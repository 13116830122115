import React from "react"
import styled from "styled-components"

import leader1 from "../assets/images/leader1.jpg"
import lukasz from "../assets/images/lukasz_gawronski.webp"
import imageBanner from "../assets/images/banner_bg_2.png"
import kgawronska from "../assets/images/katarzyna-gawronska-medinvest.jpg"
import kwisiorowski from "../assets/images/kacper-wisiorowski-medinvest.jpg"

import { StyledOnasHeader } from "../components/OnasHeader/OnasHeader.styled"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import H1 from "../components/H1/H1"
import H2 from "../components/H2/H2"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"
import Banner from "../components/Banner/Banner"
// import CardPerson from "../components/CardPerson/CardPerson"
import OnasHeader from "../components/OnasHeader/OnasHeader"

const PersonalImage = styled.div`
  display: flex;
  margin-bottom: 100px;
  flex-direction: column;
  height: auto;
  overflow: visible;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: ${({ even }) => (even ? "row-reverse" : "row")};
    margin: ${({ even }) => (even ? "0 0 150px 50px" : "0 50px 150px 0")};
  }
  img {
    object-fit: cover;
    height: auto;
    width: 300px;
    margin: 0 auto 50px auto;
    ${({ theme }) => theme.media.tablet} {
      height: 500px;
      margin: ${({ even }) => (even ? "0 0 0 50px" : "0 50px 0 0")};
    }
  }
  div {
    h2 {
      padding-left: 65px;
      ::before {
        content: "";
        height: 4px;
        width: 48px;
        background: ${({ theme }) => theme.colors.primary};
        display: inline-block;
        position: absolute;
        left: 0;
        top: calc(50% - 2px);
      }
    }
  }
`

const PartnersPage = () => {
  return (
    <Layout>
      <SEO title="O nas" />
      <div style={{ height: "60px" }} />
      <Banner title="O NAS" image={imageBanner} />
      <ContentWrapper>
        <br />
        <br />
      </ContentWrapper>
      <ContentWrapper>
        <OnasHeader />
        <PersonalImage>
          <img src={leader1} alt="Łukasz Gawroński" />
          <div>
            <H1>Łukasz Gawroński</H1>
            <H2 gold>Prezes Zarządu</H2>
            <p>
              Dzień dobry! Nazywam się Łukasz Gawroński. Na co dzień jestem
              przedsiębiorcą, managerem a przede wszystkim profesjonalnym
              pośrednikiem przy sprzedaży firm i w obrocie nieruchomościami
              sektora medycznego. Doświadczenie zdobywałem latami – studiując
              finanse i rachunkowość, prawo oraz na stanowiskach managera
              agencji nieruchomości, biura deweloperskiego oraz zarządzając
              przychodnią lekarską POZ. Rzadziej, ale z niemniejszym zapałem
              handlowałem towarami oraz sprzętem medycznym. Z branżą jestem
              związany od najmłodszych lat. Pochodzę bowiem z rodziny z lekarską
              tradycją od dziada-pradziada. W naturalny sposób sprawiło to, że
              już jako dziecko zainteresowałem się medycyną. Dorastałem, uczony
              jak zarządzać w służbie zdrowia przez osoby piastujące najwyższe
              kierownicze stanowiska w publicznych Warszawskich szpitalach.
              Wszystko, aby finalnie osiągnąć najwyższy poziom obsługi klienta
              branżowego. Wszystko, aby stworzyć MedInvest.
            </p>
          </div>
        </PersonalImage>
        <PersonalImage>
          <img src={kgawronska} alt="Katarzyna Gawrońska" />
          <div>
            <H1>Katarzyna Gawrońska</H1>
            <H2 gold>Specjalista ds. akwizycji i marketingu</H2>
            <p>
              Nazywam się Katarzyna Gawrońska i jestem specjalistką ds. akwizycji oraz marketingu. Przez lata dorastałam rozwijając i pielęgnując w sobie zaszczepione przez rodzinę pasję oraz zainteresowanie medycyną. Jestem studentką ostatniego roku wydziału lekarskiego Warszawskiego Uniwersytetu Medycznego. Posiadam nie tylko dużo doświadczeń związanych z pracą w przychodniach POZ czy szpitalach, ale przede wszystkim pośrednictwie sprzedaży tego typu przedsiębiorstw. Jeśli szukasz profesjonalistki, która zna branże od podszewki i z powodzeniem łączy pracę lekarza z pośrednictwem sprzedaży firm sektora medycznego skontaktuj się ze mną!
            </p>
          </div>
        </PersonalImage>
        <PersonalImage>
          <img src={kwisiorowski} alt="Kacper Wisiorowski" />
          <div>
            <H1>Kacper Wisiorowski</H1>
            <H2 gold>Specjalista ds. akwizycji</H2>
            <p>
              Nazywam się Kacper Wisiorowski i jestem pośrednikiem w sprzedaży nieruchomości oraz firm sektora medycznego. Moje doświadczenie w tej dziedzinie sięga już kilku lat. Przez ten czas skupiłem się wyłącznie na sprzedaży nieruchomości medycznych, co pozwoliło mi zgłębić tajniki tego specjalistycznego sektora i doskonale zrozumieć unikalne potrzeby klientów. Pochodzę z rodziny przedsiębiorców, w której od najmłodszych lat obserwowałem/łam, jak ważne jest budowanie sukcesu własnymi rękami i zarządzanie kapitałem. Ta niezwykła atmosfera rozwijała we mnie poczucie wartości pieniądza i silną motywację do osiągania sukcesów w świecie biznesu. Moja przygoda z nieruchomościami rozpoczęła się, gdy pracowałem nad sprzedażą produktów inwestycyjnych oraz ich tworzeniem. To właśnie wtedy zdobyłem niezbędne umiejętności i doświadczenie, które teraz wykorzystuję w obszarze nieruchomości medycznych. Dogłębna analiza opłacalności oraz dążenie do maksymalizacji zysków dla klientów są dla mnie niezwykle istotne. Dzięki połączeniu mojego wcześniejszego doświadczenia z nieruchomościami inwestycyjnymi i obecnej specjalizacji w sprzedaży nieruchomości medycznych, jestem w stanie zapewnić klientom profesjonalną obsługę na najwyszym poziomie, dostosowaną do ich indywidualnych potrzeb i oczekiwań. Współpraca ze mną gwarantuje nie tylko skuteczną sprzedaż, ale również pełne zrozumienie oraz pasję do tego, co robię. Jeśli szukasz eksperta, który zna się na nieruchomościach medycznych, jestem gotowy pomóc Ci w osiągnięciu Twoich celów. Skontaktuj się ze mną, a razem znajdziemy najlepsze rozwiązanie dla Ciebie i Twojego przedsiębiorstwa!
            </p>
          </div>
        </PersonalImage>
      </ContentWrapper>
    </Layout>
  )
}

export default PartnersPage
